<script setup lang="ts">
import type { CmsBlockProductThreeColumn } from '@shopware-pwa/composables-next';

const props = defineProps<{
  content: CmsBlockProductThreeColumn;
}>();

const { getSlotContent } = useCmsBlock(props.content);

const leftContent = getSlotContent('left');
const rightContent = getSlotContent('right');
const centerContent = getSlotContent('center');
</script>

<template>
  <div class="lg:px-15 !m-0 grid gap-5 px-5 md:grid-cols-3">
    <ProductCardRich
      :product="
        leftContent.data.product || { id: leftContent.config?.product?.value }
      "
    />
    <ProductCardRich
      :product="
        centerContent?.data?.product || {
          id: centerContent.config?.product?.value,
        }
      "
    />
    <ProductCardRich
      :product="
        rightContent?.data?.product || {
          id: rightContent.config?.product?.value,
        }
      "
    />
  </div>
</template>
